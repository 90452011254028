@font-face {
    font-family: gilroy;
    src: url('/fonts/Gilroy-Light.woff2') format('woff2'), url('/fonts/Gilroy-Light.woff') format('woff');
    font-display: swap;
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: gilroy;
    src: url('/fonts/Gilroy-Regular.woff2') format('woff2'), url('/fonts/Gilroy-Regular.woff') format('woff');
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: gilroy;
    src: url('/fonts/Gilroy-Medium.woff2') format('woff2'), url('/fonts/Gilroy-Medium.woff') format('woff');
    font-display: swap;
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: gilroy;
    src: url('/fonts/Gilroy-SemiBold.woff2') format('woff2'), url('/fonts/Gilroy-SemiBold.woff') format('woff');
    font-display: swap;
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: maisonNeue;
    src: url('/fonts/MaisonNeue-Light.woff2') format('woff2'), url('/fonts/MaisonNeue-Light.woff') format('woff');
    font-display: swap;
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: maisonNeue;
    src: url('/fonts/MaisonNeue-Book.woff2') format('woff2'), url('/fonts/MaisonNeue-Book.woff') format('woff');
    font-display: swap;
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: maisonNeue;
    src: url('/fonts/MaisonNeue-Medium.woff2') format('woff2'), url('/fonts/MaisonNeue-Medium.woff') format('woff');
    font-display: swap;
    font-style: normal;
    font-weight: 600;
}