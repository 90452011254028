[class^='ant-']::-ms-clear,
[class*='ant-']::-ms-clear,
[class^='ant-'] input::-ms-clear,
[class*='ant-'] input::-ms-clear,
[class^='ant-'] input::-ms-reveal,
[class*='ant-'] input::-ms-reveal {
	display: none;
}
input::-ms-clear,
input::-ms-reveal {
	display: none;
}
*,
*::before,
*::after {
	box-sizing: border-box;
}
@-ms-viewport {
	width: device-width;
}
[tabindex='-1']:focus {
	outline: none !important;
}
abbr[title],
abbr[data-original-title] {
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	border-bottom: 0;
	cursor: help;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
	-webkit-appearance: none;
}
figure {
	margin: 0 0 1em;
}
img {
	vertical-align: middle;
	border-style: none;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
	touch-action: manipulation;
}
caption {
	padding-top: 0.75em;
	padding-bottom: 0.3em;
	color: rgba(0, 0, 0, 0.45);
	text-align: left;
	caption-side: bottom;
}
input,
button,
select,
optgroup,
textarea {
	margin: 0;
	color: inherit;
	font-size: inherit;
	font-family: inherit;
	line-height: inherit;
}
button,
input {
	overflow: visible;
}
button,
select {
	text-transform: none;
}
button,
html [type='button'],
[type='reset'],
[type='submit'] {
	-webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
	padding: 0;
	border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
	box-sizing: border-box;
	padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
	-webkit-appearance: listbox;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
	height: auto;
}
[type='search'] {
	outline-offset: -2px;
	-webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}
::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}
[hidden] {
	display: none !important;
}
mark {
	padding: 0.2em;
	background-color: #feffe6;
}
.clearfix::before {
	display: table;
	content: '';
}
.clearfix::after {
	display: table;
	clear: both;
	content: '';
}
.anticon {
	display: inline-flex;
	align-items: center;
	color: inherit;
	font-style: normal;
	line-height: 0;
	text-align: center;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizelegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.anticon > * {
	line-height: 1;
}
.anticon svg {
	display: inline-block;
}
.anticon::before {
	display: none;
}
.anticon .anticon-icon {
	display: block;
}
.anticon > .anticon {
	line-height: 0;
	vertical-align: 0;
}
.anticon[tabindex] {
	cursor: pointer;
}
.anticon-spin,
.anticon-spin::before {
	display: inline-block;
	animation: loadingCircle 1s infinite linear;
}
.ant-fade-enter,
.ant-fade-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-fade-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
	animation-name: antFadeIn;
	animation-play-state: running;
}
.ant-fade-leave.ant-fade-leave-active {
	animation-name: antFadeOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-fade-enter,
.ant-fade-appear {
	opacity: 0;
	animation-timing-function: linear;
}
.ant-fade-leave {
	animation-timing-function: linear;
}
@keyframes antFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes antFadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.ant-move-up-enter,
.ant-move-up-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-move-up-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-move-up-enter.ant-move-up-enter-active,
.ant-move-up-appear.ant-move-up-appear-active {
	animation-name: antMoveUpIn;
	animation-play-state: running;
}
.ant-move-up-leave.ant-move-up-leave-active {
	animation-name: antMoveUpOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-move-up-enter,
.ant-move-up-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-up-leave {
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-down-enter,
.ant-move-down-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-move-down-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-move-down-enter.ant-move-down-enter-active,
.ant-move-down-appear.ant-move-down-appear-active {
	animation-name: antMoveDownIn;
	animation-play-state: running;
}
.ant-move-down-leave.ant-move-down-leave-active {
	animation-name: antMoveDownOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-move-down-enter,
.ant-move-down-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-down-leave {
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-left-enter,
.ant-move-left-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-move-left-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-move-left-enter.ant-move-left-enter-active,
.ant-move-left-appear.ant-move-left-appear-active {
	animation-name: antMoveLeftIn;
	animation-play-state: running;
}
.ant-move-left-leave.ant-move-left-leave-active {
	animation-name: antMoveLeftOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-move-left-enter,
.ant-move-left-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-left-leave {
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-right-enter,
.ant-move-right-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-move-right-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-move-right-enter.ant-move-right-enter-active,
.ant-move-right-appear.ant-move-right-appear-active {
	animation-name: antMoveRightIn;
	animation-play-state: running;
}
.ant-move-right-leave.ant-move-right-leave-active {
	animation-name: antMoveRightOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-move-right-enter,
.ant-move-right-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-right-leave {
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
	0% {
		transform: translateY(100%);
		transform-origin: 0 0;
		opacity: 0;
	}
	100% {
		transform: translateY(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
}
@keyframes antMoveDownOut {
	0% {
		transform: translateY(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
	100% {
		transform: translateY(100%);
		transform-origin: 0 0;
		opacity: 0;
	}
}
@keyframes antMoveLeftIn {
	0% {
		transform: translateX(-100%);
		transform-origin: 0 0;
		opacity: 0;
	}
	100% {
		transform: translateX(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
}
@keyframes antMoveLeftOut {
	0% {
		transform: translateX(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
	100% {
		transform: translateX(-100%);
		transform-origin: 0 0;
		opacity: 0;
	}
}
@keyframes antMoveRightIn {
	0% {
		transform: translateX(100%);
		transform-origin: 0 0;
		opacity: 0;
	}
	100% {
		transform: translateX(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
}
@keyframes antMoveRightOut {
	0% {
		transform: translateX(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
	100% {
		transform: translateX(100%);
		transform-origin: 0 0;
		opacity: 0;
	}
}
@keyframes antMoveUpIn {
	0% {
		transform: translateY(-100%);
		transform-origin: 0 0;
		opacity: 0;
	}
	100% {
		transform: translateY(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
}
@keyframes antMoveUpOut {
	0% {
		transform: translateY(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
	100% {
		transform: translateY(-100%);
		transform-origin: 0 0;
		opacity: 0;
	}
}
@keyframes loadingCircle {
	100% {
		transform: rotate(360deg);
	}
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
	position: relative;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	border-radius: inherit;
	box-shadow: 0 0 0 0 #1890ff;
	box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
	opacity: 0.2;
	animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-fill-mode: forwards;
	content: '';
	pointer-events: none;
}
@keyframes waveEffect {
	100% {
		box-shadow: 0 0 0 #1890ff;
		box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
	}
}
@keyframes fadeEffect {
	100% {
		opacity: 0;
	}
}
.ant-slide-up-enter,
.ant-slide-up-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-slide-up-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
	animation-name: antSlideUpIn;
	animation-play-state: running;
}
.ant-slide-up-leave.ant-slide-up-leave-active {
	animation-name: antSlideUpOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
	transform: scale(0);
	transform-origin: 0% 0%;
	opacity: 0;
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-up-leave {
	animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-down-enter,
.ant-slide-down-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-slide-down-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
	animation-name: antSlideDownIn;
	animation-play-state: running;
}
.ant-slide-down-leave.ant-slide-down-leave-active {
	animation-name: antSlideDownOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-slide-down-enter,
.ant-slide-down-appear {
	transform: scale(0);
	transform-origin: 0% 0%;
	opacity: 0;
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-down-leave {
	animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-left-enter,
.ant-slide-left-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-slide-left-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
	animation-name: antSlideLeftIn;
	animation-play-state: running;
}
.ant-slide-left-leave.ant-slide-left-leave-active {
	animation-name: antSlideLeftOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-slide-left-enter,
.ant-slide-left-appear {
	transform: scale(0);
	transform-origin: 0% 0%;
	opacity: 0;
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-left-leave {
	animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-right-enter,
.ant-slide-right-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-slide-right-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}
.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
	animation-name: antSlideRightIn;
	animation-play-state: running;
}
.ant-slide-right-leave.ant-slide-right-leave-active {
	animation-name: antSlideRightOut;
	animation-play-state: running;
	pointer-events: none;
}
.ant-slide-right-enter,
.ant-slide-right-appear {
	transform: scale(0);
	transform-origin: 0% 0%;
	opacity: 0;
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-right-leave {
	animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
	0% {
		transform: scaleY(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}
	100% {
		transform: scaleY(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}
}
@keyframes antSlideUpOut {
	0% {
		transform: scaleY(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}
	100% {
		transform: scaleY(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}
}
@keyframes antSlideDownIn {
	0% {
		transform: scaleY(0.8);
		transform-origin: 100% 100%;
		opacity: 0;
	}
	100% {
		transform: scaleY(1);
		transform-origin: 100% 100%;
		opacity: 1;
	}
}
@keyframes antSlideDownOut {
	0% {
		transform: scaleY(1);
		transform-origin: 100% 100%;
		opacity: 1;
	}
	100% {
		transform: scaleY(0.8);
		transform-origin: 100% 100%;
		opacity: 0;
	}
}
@keyframes antSlideLeftIn {
	0% {
		transform: scaleX(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}
	100% {
		transform: scaleX(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}
}
@keyframes antSlideLeftOut {
	0% {
		transform: scaleX(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}
	100% {
		transform: scaleX(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}
}
@keyframes antSlideRightIn {
	0% {
		transform: scaleX(0.8);
		transform-origin: 100% 0%;
		opacity: 0;
	}
	100% {
		transform: scaleX(1);
		transform-origin: 100% 0%;
		opacity: 1;
	}
}
@keyframes antSlideRightOut {
	0% {
		transform: scaleX(1);
		transform-origin: 100% 0%;
		opacity: 1;
	}
	100% {
		transform: scaleX(0.8);
		transform-origin: 100% 0%;
		opacity: 0;
	}
}
.ant-motion-collapse-legacy {
	overflow: hidden;
}
.ant-motion-collapse-legacy-active {
	transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
	overflow: hidden;
	transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-select-single .ant-select-selector {
	display: flex;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
	position: absolute;
	top: 0;
	right: 11px;
	bottom: 0;
	left: 11px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search-input {
	width: 100%;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	padding: 0;
	transition: all 0.3s, visibility 0s;
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	transition: none;
	pointer-events: none;
}
.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
	display: inline-block;
	width: 0;
	visibility: hidden;
	content: '\a0';
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
	right: 25px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
	padding-right: 18px;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
	color: #bfbfbf;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	width: 100%;
	padding: 0 11px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector::after {
	display: none;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
	position: static;
	width: 100%;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
	position: absolute;
	right: 0;
	left: 0;
	padding: 0 11px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
	display: none;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
	right: 7px;
	left: 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
	padding: 0 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
	right: 28px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
	padding-right: 21px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
	padding: 0 11px;
}
.ant-select-selection-overflow {
	position: relative;
	display: flex;
	flex: auto;
	flex-wrap: wrap;
	max-width: 100%;
}
.ant-select-selection-overflow-item {
	flex: none;
	align-self: center;
	max-width: 100%;
}
.ant-select-multiple .ant-select-selector {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 1px 4px;
}
.ant-select-show-search.ant-select-multiple .ant-select-selector {
	cursor: text;
}
.ant-select-disabled.ant-select-multiple .ant-select-selector {
	background: #f5f5f5;
	cursor: not-allowed;
}
.ant-select-multiple .ant-select-selector::after {
	display: inline-block;
	width: 0;
	margin: 2px 0;
	visibility: hidden;
	content: '\a0';
}
.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
	padding-right: 24px;
}
.ant-select-multiple .ant-select-selection-item {
	position: relative;
	display: flex;
	flex: none;
	box-sizing: border-box;
	max-width: 100%;
	margin-top: 2px;
	margin-bottom: 2px;
	background: #f5f5f5;
	border: 1px solid #f0f0f0;
	border-radius: 2px;
	cursor: default;
	transition: font-size 0.3s, line-height 0.3s, height 0.3s;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-inline-end: 4px;
	padding-inline-start: 8px;
	padding-inline-end: 4px;
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
	color: #bfbfbf;
	border-color: #d9d9d9;
	cursor: not-allowed;
}
.ant-select-multiple .ant-select-selection-item-content {
	display: inline-block;
	margin-right: 4px;
	overflow: hidden;
	white-space: pre;
	text-overflow: ellipsis;
}
.ant-select-multiple .ant-select-selection-item-remove {
	color: inherit;
	font-style: normal;
	line-height: 0;
	text-align: center;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizelegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: inline-flex;
	align-items: center;
	color: rgba(0, 0, 0, 0.45);
	font-weight: bold;
	font-size: 10px;
	line-height: inherit;
	cursor: pointer;
}
.ant-select-multiple .ant-select-selection-item-remove > * {
	line-height: 1;
}
.ant-select-multiple .ant-select-selection-item-remove svg {
	display: inline-block;
}
.ant-select-multiple .ant-select-selection-item-remove::before {
	display: none;
}
.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
	display: block;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
	vertical-align: middle;
}
.ant-select-multiple .ant-select-selection-item-remove:hover {
	color: rgba(0, 0, 0, 0.75);
}
.ant-select-multiple .ant-select-selection-overflow-item + .ant-select-selection-overflow-item .ant-select-selection-search {
	margin-inline-start: 0;
}
.ant-select-multiple .ant-select-selection-search {
	position: relative;
	max-width: 100%;
	margin-inline-start: 7px;
}
.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
	transition: all 0.3s;
}
.ant-select-multiple .ant-select-selection-search-input {
	width: 100%;
	min-width: 4.1px;
}
.ant-select-multiple .ant-select-selection-search-mirror {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999;
	white-space: pre;
	visibility: hidden;
}
.ant-select-multiple .ant-select-selection-placeholder {
	position: absolute;
	top: 50%;
	right: 11px;
	left: 11px;
	transform: translateY(-50%);
	transition: all 0.3s;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
	left: 7px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
	margin-inline-start: 3px;
}
.ant-select-disabled .ant-select-selection-item-remove {
	display: none;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
	background-color: transparent;
	border-color: var(--errorColor) !important;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open .ant-select-selector,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector {
	border-color: unset;
	box-shadow: unset;
	border-right-width: 0;
	outline: 0;
}
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
	background-color: #fff;
	border-color: #faad14 !important;
}
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open .ant-select-selector,
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector {
	border-color: #ffc53d;
	box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
	border-right-width: 1px;
	outline: 0;
}
.ant-select-status-error.ant-select-has-feedback .ant-select-clear,
.ant-select-status-warning.ant-select-has-feedback .ant-select-clear,
.ant-select-status-success.ant-select-has-feedback .ant-select-clear,
.ant-select-status-validating.ant-select-has-feedback .ant-select-clear {
	right: 32px;
}
.ant-select-status-error.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-warning.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-success.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-validating.ant-select-has-feedback .ant-select-selection-selected-value {
	padding-right: 42px;
}
.ant-select {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	display: inline-block;
	cursor: pointer;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	position: relative;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
	cursor: pointer;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	cursor: text;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
	cursor: auto;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-color: transparent;
	box-shadow: unset;
	border-right-width: unset;
	outline: 0;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	cursor: not-allowed;
}
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	background: #f5f5f5;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
	cursor: not-allowed;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
	margin: 0;
	padding: 0;
	background: transparent;
	border: none;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
	display: none;
	-webkit-appearance: none;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
	border-color: #40a9ff;
	border-right-width: 1px;
}
.ant-select-selection-item {
	flex: 1;
	overflow: hidden;
	font-weight: normal;
	white-space: nowrap;
	text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
	.ant-select-selection-item *::-ms-backdrop,
	.ant-select-selection-item {
		flex: auto;
	}
}
.ant-select-selection-placeholder {
	flex: 1;
	overflow: hidden;
	color: #bfbfbf;
	white-space: nowrap;
	text-overflow: ellipsis;
	pointer-events: none;
}
@media all and (-ms-high-contrast: none) {
	.ant-select-selection-placeholder *::-ms-backdrop,
	.ant-select-selection-placeholder {
		flex: auto;
	}
}
.ant-select-arrow {
	display: inline-flex;
	color: inherit;
	font-style: normal;
	line-height: 0;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizelegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: absolute;
	top: 50%;
	right: 11px;
	display: flex;
	align-items: center;
	margin-top: -6px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	line-height: 1;
	text-align: center;
	pointer-events: none;
}
.ant-select-arrow > * {
	line-height: 1;
}
.ant-select-arrow svg {
	display: inline-block;
}
.ant-select-arrow::before {
	display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
	display: block;
}
.ant-select-arrow .anticon {
	vertical-align: top;
	transition: transform 0.3s;
}
.ant-select-arrow .anticon > svg {
	vertical-align: top;
}
.ant-select-arrow .anticon:not(.ant-select-suffix) {
	pointer-events: auto;
}
.ant-select-disabled .ant-select-arrow {
	cursor: not-allowed;
}
.ant-select-arrow > *:not(:last-child) {
	margin-inline-end: 8px;
}
.ant-select-clear {
	position: absolute;
	top: 50%;
	right: 11px;
	z-index: 1;
	display: inline-block;
	width: 12px;
	height: 12px;
	margin-top: -6px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	font-style: normal;
	line-height: 1;
	text-align: center;
	text-transform: none;
	background: #fff;
	cursor: pointer;
	opacity: 0;
	transition: color 0.3s ease, opacity 0.15s ease;
	text-rendering: auto;
}
.ant-select-clear::before {
	display: block;
}
.ant-select-clear:hover {
	color: rgba(0, 0, 0, 0.45);
}
.ant-select:hover .ant-select-clear {
	opacity: 1;
}
.ant-select-dropdown {
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: 'tnum';
	position: absolute;
	top: -9999px;
	left: -9999px;
	z-index: 1050;
	box-sizing: border-box;
	padding: 4px 0;
	overflow: hidden;
	font-size: 14px;
	font-variant: initial;
	background-color: #fff;
	border-radius: 2px;
	outline: none;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
	animation-name: antSlideUpIn;
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-topLeft {
	animation-name: antSlideDownIn;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
	animation-name: antSlideUpOut;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-topLeft {
	animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
	display: none;
}
.ant-select-dropdown-empty {
	color: rgba(0, 0, 0, 0.25);
}
.ant-select-item-empty {
	position: relative;
	display: block;
	min-height: 32px;
	padding: 5px 12px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: normal;
	color: rgba(0, 0, 0, 0.25);
}
.ant-select-item {
	position: relative;
	display: block;
	padding: 5px 12px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: normal;
	cursor: pointer;
	transition: background 0.3s ease;
}
.ant-select-item-group {
	color: rgba(0, 0, 0, 0.45);
	cursor: default;
}
.ant-select-item-option {
	display: flex;
}
.ant-select-item-option-content {
	flex: auto;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ant-select-item-option-state {
	flex: none;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	background-color: #f5f5f5;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	color: rgba(0, 0, 0, 0.85);
	font-weight: 600;
	background-color: #e6f7ff;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
	color: #1890ff;
}
.ant-select-item-option-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-select-item-option-disabled.ant-select-item-option-selected {
	background-color: #f5f5f5;
}
.ant-select-item-option-grouped {
	padding-left: 24px;
}
.ant-select-lg {
	font-size: 16px;
}
.ant-select-borderless .ant-select-selector {
	background-color: transparent !important;
	border-color: transparent !important;
	box-shadow: none !important;
}
.ant-select.ant-select-in-form-item {
	width: 100%;
}
.ant-select-compact-item:not(.ant-select-compact-last-item) {
	margin-right: -1px;
}
.ant-select-compact-item:not(.ant-select-compact-last-item).ant-select-compact-item-rtl {
	margin-right: 0;
	margin-left: -1px;
}
.ant-select-compact-item:hover > *,
.ant-select-compact-item:focus > *,
.ant-select-compact-item:active > * {
	z-index: 2;
}
.ant-select-compact-item.ant-select-focused > * {
	z-index: 2;
}
.ant-select-compact-item[disabled] > * {
	z-index: 0;
}
.ant-select-compact-item:not(.ant-select-compact-first-item):not(.ant-select-compact-last-item).ant-select > .ant-select-selector {
	border-radius: 0;
}
.ant-select-compact-item.ant-select-compact-first-item.ant-select:not(.ant-select-compact-last-item):not(.ant-select-compact-item-rtl) > .ant-select-selector {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.ant-select-compact-item.ant-select-compact-last-item.ant-select:not(.ant-select-compact-first-item):not(.ant-select-compact-item-rtl) > .ant-select-selector {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.ant-select-compact-item.ant-select.ant-select-compact-first-item.ant-select-compact-item-rtl:not(.ant-select-compact-last-item) > .ant-select-selector {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.ant-select-compact-item.ant-select.ant-select-compact-last-item.ant-select-compact-item-rtl:not(.ant-select-compact-first-item) > .ant-select-selector {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.ant-select-rtl {
	direction: rtl;
}
.ant-select-rtl .ant-select-arrow {
	right: initial;
	left: 11px;
}
.ant-select-rtl .ant-select-clear {
	right: initial;
	left: 11px;
}
.ant-select-dropdown-rtl {
	direction: rtl;
}
.ant-select-dropdown-rtl .ant-select-item-option-grouped {
	padding-right: 24px;
	padding-left: 12px;
}
.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
	padding-right: 4px;
	padding-left: 24px;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
	text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
	margin-right: 0;
	margin-left: 4px;
	text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
	right: 0;
	left: auto;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
	right: 11px;
	left: auto;
}
.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
	right: 7px;
}
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	right: 0;
	left: 9px;
	text-align: right;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
	right: 11px;
	left: 25px;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
	padding-right: 0;
	padding-left: 18px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
	right: 6px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
	padding-right: 0;
	padding-left: 21px;
}
.ant-empty {
	margin: 0 8px;
	font-size: 14px;
	text-align: center;
}
.ant-empty-image {
	height: 100px;
	margin-bottom: 8px;
}
.ant-empty-image img {
	height: 100%;
}
.ant-empty-image svg {
	height: 100%;
	margin: auto;
}
.ant-empty-footer {
	margin-top: 16px;
}
.ant-empty-normal {
	margin: 32px 0;
	color: rgba(0, 0, 0, 0.25);
}
.ant-empty-normal .ant-empty-image {
	height: 40px;
}
.ant-empty-small {
	margin: 8px 0;
	color: rgba(0, 0, 0, 0.25);
}
.ant-empty-small .ant-empty-image {
	height: 35px;
}
.ant-empty-img-default-ellipse {
	fill: #f5f5f5;
	fill-opacity: 0.8;
}
.ant-empty-img-default-path-1 {
	fill: #aeb8c2;
}
.ant-empty-img-default-path-2 {
	fill: url('#linearGradient-1');
}
.ant-empty-img-default-path-3 {
	fill: #f5f5f7;
}
.ant-empty-img-default-path-4 {
	fill: #dce0e6;
}
.ant-empty-img-default-path-5 {
	fill: #dce0e6;
}
.ant-empty-img-default-g {
	fill: #fff;
}
.ant-empty-img-simple-ellipse {
	fill: #f5f5f5;
}
.ant-empty-img-simple-g {
	stroke: #d9d9d9;
}
.ant-empty-img-simple-path {
	fill: #fafafa;
}
.ant-empty-rtl {
	direction: rtl;
}
